import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";

export default function FAQ() {
  return (
    <>
      <div className="ecommerce-title" style={{ marginBottom: "50px" }}>
        <h1 className="text-color">Have Questions? Look Here</h1>
        <p>
          We’ve compiled a comprehensive FAQ section to help you with any
          questions you might have about our ecommerce app.
        </p>
      </div>

      <div>
        <div className="card" style={{ maxWidth: "80%", margin: "0 auto" }}>
          <Accordion>
            <AccordionTab header="Can I get support from the developer?">
              <p className="m-0">
                Yes, we offer dedicated support for our users. You can reach out
                to our support team via email or through our website's contact
                form. We are here to assist you with any questions or issues you
                may have.
              </p>
              <Button
                variant="contained"
                color="primary"
                style={{ border: "none" }}
                onClick={() => (window.location.href = "/contact")}
              >
                Contact Us
              </Button>
            </AccordionTab>
            <AccordionTab header="Do you offer a free trial?">
              <p className="m-0">
                Yes, we provide a free trial period so you can explore and test
                our app's features before making a commitment. Contact us for
                more details on how to start your free trial.
              </p>
              <Button
                variant="contained"
                color="primary"
                style={{ border: "none" }}
                onClick={() => (window.location.href = "/contact")}
              >
                Contact Us
              </Button>
            </AccordionTab>
            <AccordionTab header="Can I edit my personal information?">
              <p className="m-0">
                Absolutely. You can easily update your personal information by
                accessing the settings section of your account within the app.
                Make sure to save any changes to keep your details up-to-date.
              </p>
              <Button
                variant="contained"
                color="primary"
                style={{ border: "none" }}
                onClick={() => (window.location.href = "/contact")}
              >
                Contact Us
              </Button>
            </AccordionTab>
            <AccordionTab header="How do I contact your team?">
              <p className="m-0">
                To get in touch with our team, you can use the contact form on
                our website or send us an email. We also offer support through
                our customer service phone line during business hours.
              </p>
              <Button
                variant="contained"
                color="primary"
                style={{ border: "none" }}
                onClick={() => (window.location.href = "/contact")}
              >
                Contact Us
              </Button>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </>
  );
}
