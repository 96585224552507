import React, { useEffect } from "react";
import Menu from "./menu";
import "../sass/about.scss";
import EcommerceProject from "./projects/ecommerce/ecommerce";

export default function AboutUs() {
  useEffect(() => {
    document.title = "Project - Denyark Web Service";
  }, []);

  return (
    <>
      <div className="about-us-body">
        <div className="about-us-container">
          <Menu />
          <div>
            <h1 className="about-us-title">PROJECT</h1>
            <p className="body-container-title">
              {" "}
              <span
                className="p active"
                onClick={() => (window.location.href = "/")}
              >
                Home
              </span>{" "}
              / <span>Project</span>
            </p>
          </div>
        </div>
      </div>
      <EcommerceProject />
    </>
  );
}
