import React, { useState } from "react";
import { FiPhone, FiMail } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa"; // Importing WhatsApp icon from FontAwesome

const SocialIcons = () => {
  const [hovered, setHovered] = useState(null); // Track which icon is hovered

  const iconStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  };

  const buttonStyles = {
    fontSize: "24px",
    borderRadius: "50%",
    padding: "12px",
    textDecoration: "none",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    transition: "background-color 0.3s ease, transform 0.3s ease",
    cursor: "pointer",
  };

  const phoneStyles = {
    ...buttonStyles,
    backgroundColor: "#4CAF50",
    ...(hovered === "phone" ? { opacity: 0.8, transform: "scale(1.05)" } : {}),
  };

  const emailStyles = {
    ...buttonStyles,
    backgroundColor: "#1DA1F2",
    ...(hovered === "email" ? { opacity: 0.8, transform: "scale(1.05)" } : {}),
  };

  const whatsappStyles = {
    ...buttonStyles,
    backgroundColor: "#25D366",
    ...(hovered === "whatsapp"
      ? { opacity: 0.8, transform: "scale(1.05)" }
      : {}),
  };

  return (
    <div style={iconStyles}>
      <a
        href="tel:+233545156156"
        target="_blank"
        rel="noopener noreferrer"
        style={phoneStyles}
        onMouseEnter={() => setHovered("phone")}
        onMouseLeave={() => setHovered(null)}
      >
        <FiPhone />
      </a>
      <a
        href="mailto:contact@denyark.com"
        target="_blank"
        rel="noopener noreferrer"
        style={emailStyles}
        onMouseEnter={() => setHovered("email")}
        onMouseLeave={() => setHovered(null)}
      >
        <FiMail />
      </a>
      <a
        href="https://wa.me/233545156156"
        target="_blank"
        rel="noopener noreferrer"
        style={whatsappStyles}
        onMouseEnter={() => setHovered("whatsapp")}
        onMouseLeave={() => setHovered(null)}
      >
        <FaWhatsapp />
      </a>
    </div>
  );
};

export default SocialIcons;
