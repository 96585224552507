import React from "react";
import image from "../../images/developer1.jpg";
import "../../sass/design.scss";
import SocialIcons from "./SocialIcons";

export default function Develop() {
  return (
    <div style={styles.container} className="design-container">
      <div className="design-overlay"></div>
      <div className="design-content">
        <SocialIcons />
        <h1 style={styles.text} className="design-text">
          From Design to Development
        </h1>
        <p style={styles.description}>
          We go beyond stunning designs to build high-performance applications.
          Our team turns creative concepts into functional, reliable solutions,
          ensuring seamless user experiences. Let us elevate your vision with
          expert development that delivers results.
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundImage: `url(${image})`,
  },

  description: {
    color: "#fff",
    fontSize: "1.2rem",
    margin: "10px 0 0",
  },

  iconLink: {
    color: "#fff",
    textDecoration: "none",
  },
  icon: {
    fontSize: "2rem",
  },
};
