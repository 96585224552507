import React from "react";
import "../sass/welcomePage.scss";
import { ProgressSpinner } from 'primereact/progressspinner';

const WelcomePage = () => {
  return (
    <div className="welcome-container">
      <div className="welcome-progress"><div className="card">
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
        </div>
      </div>
      <div className="welcome-text">
        <p>www.denyark.com</p>
      </div>
    </div>
  );
};

export default WelcomePage;
