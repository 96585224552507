import React from "react";
import image from "../../images/office-620817_1280.jpg";
import "../../sass/design.scss";
import SocialIcons from "./SocialIcons";

export default function Design() {
  return (
    <div style={styles.container} className="design-container">
      <div className="design-overlay"></div>
      <div className="design-content">
        <SocialIcons />
        <h1 style={styles.text} className="design-text">
          Crafting Stunning Designs
        </h1>
        <p style={styles.description}>
          We specialize in creating beautiful and functional designs that make a
          lasting impression. From eye-catching websites to intuitive mobile
          apps, our design team is dedicated to bringing your vision to life
          with creativity and precision. Let us transform your ideas into a
          visual masterpiece.
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundImage: `url(${image})`,
  },

  description: {
    color: "#fff",
    fontSize: "1.2rem",
    margin: "10px 0 0",
  },
  icons: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
  },
  iconLink: {
    color: "#fff",
    textDecoration: "none",
  },
  icon: {
    fontSize: "2rem",
  },
};
