// src/WelcomeHomePage.js

import React from "react";
import "../sass/welcomeHomePage.scss";
import logo from "../images/logo.svg";
import bgImage from "../images/apple-1867461_1280.jpg";

const WelcomeHomePage = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div
      className="welcome-homepage"
      style={{ backgroundImage: `url(${bgImage})`, width: "100%" }}
    >
      <main className="main-content">
        <img src={logo} alt="" srcset="" style={{ width: "150px"}} />

        <p>&copy; {currentYear} Denyark</p>
        <p>Web Service</p>
      </main>
    </div>
  );
};

export default WelcomeHomePage;
