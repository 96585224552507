import React, { useEffect, useState } from "react";
import Menu from "./menu";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MailIcon from "@mui/icons-material/Mail";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Button } from "primereact/button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import emailjs from "emailjs-com";
import "../sass/contact.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./footer";

export default function Contact() {
  useEffect(() => {
    document.title = "Contact Us - Denyark Software Limited";
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1498d6",
      },
      background: {
        default: "#1498d6",
        paper: "#333333",
      },
      text: {
        primary: "#ffffff",
      },
    },
  });

  const [formData, setFormData] = useState({
    from_name: "",
    reply_to: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (formData.from_name && formData.reply_to && formData.message) {
      setLoading(true);
      const data = {
        ...formData,
        to_name: "Denyark Team",
      };
      emailjs
        .send(
          "service_6t6c3gq", // Replace with your EmailJS service ID
          "template_9iov5zs", // Replace with your EmailJS template ID
          data,
          "m3OFl-xjPi3F_lq59" // Replace with your EmailJS user ID
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setLoading(false);
          setAlert({
            open: true,
            severity: "success",
            message:
              "Thank you for reaching out to Denyark! We have received your message and our team will get back to you as soon as possible.",
          });
          setFormData({
            from_name: "",
            reply_to: "",
            message: "",
          });
          setSubmitted(false);
        })
        .catch((err) => {
          console.error("FAILED...", err);
          setLoading(false);
          setAlert({
            open: true,
            severity: "error",
            message: "Failed to send message. Please try again later.",
          });
        });
    }
  };
  const handleWhatsAppClick = () => {
    const phoneNumber = "233545156156";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

 
  return (
    <>
      <div className="about-us-body">
        <div className="about-us-container">
          <Menu />
          <div>
            <h1 className="about-us-title">GET IN TOUCH</h1>
            <p className="body-container-title">
              <span
                className="p active"
                onClick={() => (window.location.href = "/")}
              >
                Home
              </span>{" "}
              / <span>Get in Touch</span>
            </p>
          </div>
        </div>
      </div>
      <div className="contact-container">
        <div className="contact-column1">
          <div className="contact-col1-div">
            <h1>Phone</h1>
            <p className="countact-p2">
              <PhoneAndroidIcon className="contact-icon" /> +233 545 156 156
            </p>
          </div>
          <div className="contact-col1-div">
            <h1>Email</h1>
            <p className="countact-p2">
              <MailIcon className="contact-icon" /> contact@denyark.com
            </p>
          </div>
          <div className="contact-col1-div">
            <h1>WhatsApp</h1>
            <p className="countact-p2" onClick={handleWhatsAppClick} >
              <WhatsAppIcon className="contact-icon" /> +233 545 156 156
            </p>
          </div>
        </div>
        <div className="contact-column2">
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                margin: "0 auto",
                color: "#fff",
                borderRadius: "5px",
              }}
            >
              <form onSubmit={handleSubmit} noValidate>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    variant="filled"
                    name="from_name"
                    value={formData.from_name}
                    onChange={handleChange}
                    InputProps={{
                      style: { color: "#a5a4a4" },
                    }}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    required
                  />
                  {submitted && !formData.from_name && (
                    <small className="p-error">Name is required.</small>
                  )}
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    variant="filled"
                    type="email"
                    name="reply_to"
                    value={formData.reply_to}
                    onChange={handleChange}
                    InputProps={{
                      style: { color: "#a5a4a4" },
                    }}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    required
                  />
                  {submitted && !formData.reply_to && (
                    <small className="p-error">Email is required.</small>
                  )}
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Message"
                    variant="filled"
                    multiline
                    rows={5}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    InputProps={{
                      style: { color: "#a5a4a4" },
                    }}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    required
                  />
                  {submitted && !formData.message && (
                    <small className="p-error">Message is required.</small>
                  )}
                </Box>
                <Collapse in={alert.open}>
                  <Alert
                    severity={alert.severity}
                    onClose={() => setAlert({ ...alert, open: false })}
                  >
                    <AlertTitle>
                      {alert.severity === "success" ? "Success" : "Error"}
                    </AlertTitle>
                    {alert.message}
                  </Alert>
                </Collapse>
                <div style={{ textAlign: "left" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    style={{ backgroundColor: "#282c34", border: "none" }}
                  >
                    {loading ? "Sending..." : "Submit"}
                  </Button>
                </div>
              </form>
            </Box>
          </ThemeProvider>
        </div>
      </div>
      <Footer />
    </>
  );
}
