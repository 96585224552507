import React from "react";
import "../../../sass/ecommerce.scss";
import img1 from "../../../images/ecommerce/image1.png";
import a from "../../../images/ecommerce/a.png";
import b from "../../../images/ecommerce/b.png";
import c from "../../../images/ecommerce/c.png";
import d from "../../../images/ecommerce/d.png";
import e from "../../../images/ecommerce/e.png";
import f from "../../../images/ecommerce/f.png";
import g from "../../../images/ecommerce/g.png";
import h from "../../../images/ecommerce/h.png";
import i from "../../../images/ecommerce/i.png";
import j from "../../../images/ecommerce/image2.png";
import l from "../../../images/ecommerce/13.png";
import quote from "../../../images/ecommerce/quote.png";
import profile1 from "../../../images/ecommerce/profile1.png";
import profile2 from "../../../images/ecommerce/profile2.png";
import profile3 from "../../../images/ecommerce/profile3.png";
import { Button } from "primereact/button";
import { Rating } from "@mui/material"; 
import "aos/dist/aos.css";
import Intertfaces from "../ecommerce/interfaces";
import FAQ from "../ecommerce/faq";
import Footer from "../../footer";
import specialOffer from "../../../images/ecommerce/offer1.png";


export default function EcommerceProject() {
  return (
    <>
      <div className="ecommerce-title">
        <img
          src={specialOffer}
          alt=""
          srcset=""
        />
        <h1 className="text-color">
          Enhance Your Online Business with Our Ecommerce App
        </h1>
        <p>
          Our app offers a seamless, efficient solution tailored for small
          business needs, enabling them to thrive in a competitive market.
        </p>
      </div>

      <div className="ecommerce-features-container">
        <div className="ecommerce-features-col1">
          <div className="ecommerce-col1-content-container">
            <div className="ecommerce-inner">
              <img src={a} alt="Fully functional" />
            </div>
            <div className="ecommerce-inner-2">
              <h1 className="text-color">Fully Functional</h1>
              <p>
                Our app is built to handle all your ecommerce needs, from
                product listings to order management and payment processing,
                ensuring a seamless experience.
              </p>
            </div>
          </div>
          <div className="ecommerce-col1-content-container">
            <div className="ecommerce-inner">
              <img src={b} alt="Live Chat" />
            </div>
            <div className="ecommerce-inner-2">
              <h1 className="text-color">SMS Integration</h1>
              <p>
                Stay connected with your customers through our robust SMS
                integration. Send order updates,and important notifications
                directly to their phones.
              </p>
            </div>
          </div>
          <div className="ecommerce-col1-content-container">
            <div className="ecommerce-inner">
              <img src={c} alt="Secure Data" />
            </div>
            <div className="ecommerce-inner-2">
              <h1 className="text-color">Secure Data</h1>
              <p>
                We prioritize the security of your data with advanced encryption
                and security protocols, ensuring your business and customer
                information are always safe.
              </p>
            </div>
          </div>
        </div>

        <div className="ecommerce-features-col2">
          <img src={img1} alt="" style={{ width: "50%" }} />
        </div>
        <div className="ecommerce-features-col3">
          <div className="ecommerce-col1-content-container">
            <div className="ecommerce-inner">
              <img src={d} alt="Easy to Customize" />
            </div>
            <div className="ecommerce-inner-2">
              <h1 className="text-color">Easy to Customize</h1>
              <p>
                Tailor your online store to reflect your unique brand with our
                easy-to-use customization tools. No coding skills required!
              </p>
            </div>
          </div>
          <div className="ecommerce-col1-content-container">
            <div className="ecommerce-inner">
              <img src={e} alt="Responsive Design" />
            </div>
            <div className="ecommerce-inner-2">
              <h1 className="text-color">Responsive Design</h1>
              <p>
                Our app ensures your store looks great on all devices, providing
                an optimal shopping experience for your customers whether they
                are on a desktop, tablet, or mobile phone.
              </p>
            </div>
          </div>
          <div className="ecommerce-col1-content-container">
            <div className="ecommerce-inner">
              <img src={f} alt="Help Documentation" />
            </div>
            <div className="ecommerce-inner-2">
              <h1 className="text-color">Help Documentation</h1>
              <p>
                Access comprehensive help documentation to assist you with any
                questions or challenges you might encounter while using our app.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ecommerce-step-2-container">
        <div className="ecommerce-title">
          <h1>How Our Ecommerce Platform Works</h1>
          <p>
            Discover the simple steps to get started with our ecommerce platform
            and transform your business operations. Our intuitive process
            ensures you can quickly and easily begin enjoying all the benefits
            our platform has to offer.
          </p>
        </div>

        <div className="ecommerce-step-2-column-container">
          <div className="ecommerce-step2-col">
            <img src={g} alt="Sign Up" />
            <h1>Sign Up</h1>
            <p>
              Create your account on our ecommerce platform by filling in your
              details. The sign-up process is quick and straightforward,
              allowing you to get started in no time.
            </p>
          </div>
          <div className="ecommerce-step2-col">
            <img src={h} alt="Set Up Your Store" />
            <h1>Set Up Your Store</h1>
            <p>
              Configure your business profile by adding essential details and
              customizing your store settings. Our user-friendly interface makes
              the setup process easy and efficient.
            </p>
          </div>
          <div className="ecommerce-step2-col">
            <img src={i} alt="Enjoy the Features!" />
            <h1>Enjoy the Features!</h1>
            <p>
              Start exploring and utilizing the powerful features of our
              ecommerce platform. Manage orders, engage with customers, and grow
              your business with ease.
            </p>
          </div>
        </div>
      </div>

      <div className="ecommerce-step-3-container">
        <div className="ecommerce-step-3-col1">
          <h1 className="text-color">Effortless Product Management</h1>
          <div>
            <li>
              <span className="ecommerce-step-3-4-semi-text">
                {" "}
                Comprehensive Catalog :
              </span>{" "}
              Easily manage and display your entire product catalog with
              detailed descriptions and pricing.
            </li>
            <li>
              <span className="ecommerce-step-3-4-semi-text">
                {" "}
                Dynamic Pricing :
              </span>{" "}
              Update product prices and promotions in real-time to reflect the
              latest offers and deals.
            </li>
            <li>
              <span className="ecommerce-step-3-4-semi-text">
                Advanced Filtering :
              </span>{" "}
              Allow customers to quickly find products they’re interested in
              with powerful search and filter options.
            </li>
            <li>
              <span className="ecommerce-step-3-4-semi-text">
                {" "}
                Inventory Tracking :
              </span>{" "}
              Keep track of stock levels and receive notifications for low
              inventory to prevent stockouts.
            </li>
            <div>
              <Button
                variant="contained"
                color="primary"
                className="black-button"
                onClick={() => (window.location.href = "/contact")}
              >
                Request Demo
              </Button>
            </div>
          </div>
        </div>
        <div className="ecommerce-step-3-col2">
          <img src={j} alt="Product Listing" style={{ width: "40%" }} />
        </div>
      </div>

      <div className="ecommerce-step-4-container-main">
        <div className="ecommerce-step-4-container">
          <div className="ecommerce-step-4-col1">
            <img src={l} alt="Order Tracking" />
          </div>
          <div className="ecommerce-step-4-col2">
            <h1 className="text-color">Efficient Order Tracking System</h1>
            <li>
              <span className="ecommerce-step-3-4-semi-text">
                Real-time Tracking:
              </span>{" "}
              Track customer orders and delivery locations in real-time to
              optimize delivery routes and enhance efficiency.
            </li>
            <li>
              <span className="ecommerce-step-3-4-semi-text">
                Address Verification:
              </span>{" "}
              Validate delivery addresses automatically to ensure accurate and
              timely deliveries.
            </li>
            <li>
              <span className="ecommerce-step-3-4-semi-text">
                Customer Notifications:
              </span>{" "}
              Keep customers informed with automated notifications about their
              order status and estimated delivery times.
            </li>
            <div>
              <Button
                variant="contained"
                className="black-button"
                onClick={() => (window.location.href = "/contact")}
              >
                Request Demo
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Intertfaces />
      <FAQ />

      <div className="ecommerce-step-5-container-main">
        <div className="ecommerce-title">
          <h1 className="text-color">
            Real Feedback from Developers Who Tested Our App
          </h1>
          <p>
            Developers Share Their Experience with the Benefits of Our Ecommerce
            App.
          </p>
        </div>
        <div className="ecommerce-step-5-container">
          <div className="ecommerce-step-5-col">
            <div className="ecommerce-step-5-content">
              <div className="ecommerce-rating">
                <Rating value={4.5} />
                <img src={quote} alt="Quote" />
              </div>
              <h1 className="text-color">Highly Customizable!</h1>
              <p>
                "We loved how we could tailor the app to suit our business
                needs. The customization options are extensive and easy to
                implement. Any issues we had were resolved promptly by the
                support team."
              </p>
            </div>
            <div className="ecommerce-step-5-profile">
              <img src={profile2} alt="Tester Profile" />
              <div>
                <div>
                  <h1>Emma Collins</h1>
                </div>
                <div>
                  <p>Developer</p>
                </div>
              </div>
            </div>
          </div>
          <div className="ecommerce-step-5-col">
            <div className="ecommerce-step-5-content">
              <div className="ecommerce-rating">
                <Rating value={5} />
                <img src={quote} alt="Quote" />
              </div>
              <h1 className="text-color">Outstanding Usability!</h1>
              <p>
                "The app’s interface is incredibly intuitive. We were able to
                navigate through all the features without any hassle. The design
                is clean and user-friendly."
              </p>
            </div>
            <div className="ecommerce-step-5-profile">
              <img src={profile1} alt="Tester Profile" />
              <div>
                <div>
                  <h1>Junaid Hasan</h1>
                </div>
                <div>
                  <p>UX Designer</p>
                </div>
              </div>
            </div>
          </div>
          <div className="ecommerce-step-5-col">
            <div className="ecommerce-step-5-content">
              <div className="ecommerce-rating">
                <Rating value={5} />
                <img src={quote} alt="Quote" />
              </div>
              <h1 className="text-color">Efficient Order Management!</h1>
              <p>
                "Managing orders has never been easier. The app’s features allow
                us to keep track of everything in real-time, improving our
                overall efficiency. Highly recommend it to other businesses."
              </p>
            </div>
            <div className="ecommerce-step-5-profile">
              <img src={profile3} alt="Tester Profile" />
              <div>
                <div>
                  <h1>Alex Johnson</h1>
                </div>
                <div>
                  <p>System Analyst</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ecommerce-step-6-container-main">
        <div className="ecommerce-title">
          <h1>Interested in Our App?</h1>
          <p>
            Discover how our ecommerce app can transform your business. Whether
            you have questions, need more information, or are ready to get
            started, our team is here to assist you. Reach out to us and let's
            explore the possibilities together.
          </p>

          <Button
            variant="contained"
            color="primary"
            style={{ border: "none", marginBottom: "50px" }}
            onClick={() => (window.location.href = "/contact")}
          >
            Contact Us
          </Button>
        </div>
      </div>

      <Footer />
    </>
  );
}
