import React, { useState } from "react";
import Design from "./Design";
import Develop from "./Develop";
import Deploy from "./Deploy";
import { Button } from "primereact/button";
import WelcomeHomePage from "../welcomeHomePage";
import Menu from "../menu";

const Homepage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      id: 1,
      content: <WelcomeHomePage />,
    },
    {
      id: 2,
      content: <Design />,
    },
    {
      id: 3,
      content: <Develop />,
    },
    {
      id: 4,
      content: <Deploy />,
    },
  ];

  const handleNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  return (
    <div style={styles.container}>
        <Menu />
      <div style={styles.slidesContainer}>
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            style={{
              ...styles.slide,
              transform: `translateX(${(index - currentSlide) * 100}%)`,
            }}
          >
            {slide.content}
          </div>
        ))}

        {/* Slide Indicators */}
        <div style={styles.indicatorContainer}>
          {slides.map((_, index) => (
            <span
              key={index}
              style={{
                ...styles.indicator,
                backgroundColor: currentSlide === index ? "#007BFF" : "#ccc",
              }}
            ></span>
          ))}
        </div>

        {/* Slide Navigation Buttons */}
        <div style={styles.buttonContainer}>
          {currentSlide > 0 && (
            <Button
              icon="pi pi-angle-left"
              rounded
              raised
              aria-label="Previous Slide"
              onClick={handlePrevious}
              style={styles.navigationButton}
            />
          )}
          {currentSlide < slides.length - 1 ? (
            <Button
            raised
              icon="pi pi-angle-right"
              rounded
              aria-label="Next Slide"
              onClick={handleNext}
              style={styles.navigationButton}
            />
          ) : (
            <button style={styles.button} onClick={()=>window.location.href="/contact"} >Contact Us</button>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  slidesContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  slide: {
    minWidth: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "transform 0.5s ease-in-out",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  buttonContainer: {
    position: "absolute",
    bottom: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  indicatorContainer: {
    position: "absolute",
    bottom: "60px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: "30px",
  },
  indicator: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    margin: "0 5px",
    backgroundColor: "#ccc",
    transition: "background-color 0.3s ease",
  },
  navigationButton: {
    padding: "0.5em",
  },
};

export default Homepage;
