import React, { useState } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhoneIcon from "@mui/icons-material/Phone";
import { styled } from "@mui/material/styles";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  "& .MuiFab-primary": {
    backgroundColor: "#1498d6",
    "&:hover": {
      backgroundColor: "black",
    },
    width: 40,
    height: 40,
  },
}));

const StyledSpeedDialAction = styled(SpeedDialAction)(({ theme }) => ({
  backgroundColor: "#1498d6",
  color: "white",
  "&:hover": {
    backgroundColor: "black",
  },
}));

const navigateToHome = () => {
  window.location.href = "/";
};
const navigateToProject = () => {
  window.location.href = "/project";
};

const navigateToContact = () => {
  window.location.href = "/contact";
};

const actions = [
  {
    icon: <HomeIcon className="speed-dial-action" />,
    name: "HOME",
    onClick: navigateToHome,
  },
  {
    icon: <InfoIcon className="speed-dial-action" />,
    name: "PROJECT ",
    onClick: navigateToProject,
  },
  {
    icon: <PhoneIcon className="speed-dial-action" />,
    name: "CONTACT",
    onClick: navigateToContact,
  },
];

export default function Menu() {
  const [direction] = React.useState("down");
  const [hidden] = React.useState(false);
  const [visibleLeft, setVisibleLeft] = useState(false);

  return (
    <>
      <div className="menu-container">
        <Button
          icon="pi pi-align-justify"
          raised
          rounded
          severity="secondary"
          aria-label="Bookmark"
          onClick={() => setVisibleLeft(true)}
          className="mobile-menu"
          style={{ backgroundColor: "black", border: "1px solid white" }}
        />
        <Box
          sx={{ transform: "translateZ(0px)", flexGrow: 1 }}
          className={`desktop-menu `}
        >
          <Box sx={{ position: "relative" }}>
            <StyledSpeedDial
              ariaLabel="SpeedDial"
              hidden={hidden}
              icon={<SpeedDialIcon />}
              direction={direction}
            >
              {actions.map((action) => (
                <StyledSpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen="true"
                  onClick={action.onClick} // This is where the onClick handler is assigned
                />
              ))}
            </StyledSpeedDial>
          </Box>
        </Box>

        <Sidebar
          header="DENYARK"
          visible={visibleLeft}
          position="left"
          onHide={() => setVisibleLeft(false)}
          style={{ backgroundColor: "black", width: "85%", height: "100%" }}
          className="custom-sidebar"
          blockScroll
        >
          <div className="menu-box" onClick={navigateToHome}>
            <i className="pi pi-home"></i>
            <p>HOME</p>
          </div>
          <div className="menu-box" onClick={navigateToProject}>
            <i className="pi pi-info-circle"></i>
            <p>PROJECT</p>
          </div>
          <div className="menu-box" onClick={navigateToContact}>
            <i className="pi pi-phone"></i>
            <p>CONTACT</p>
          </div>
        </Sidebar>
      </div>
    </>
  );
}
