import React from "react";
import logo from "../images/empty-logo.png";

export default function PageNotFound() {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundColor: "black",
      color: "#333",
      fontFamily: "Arial, sans-serif",
      textAlign: "center",
    },
    heading: {
      fontSize: "4em",
      marginBottom: "20px",
    },
    subheading: {
      fontSize: "1.5em",
      marginBottom: "40px",
    },
    link: {
      padding: "10px 20px",
      fontSize: "1em",
      color: "#fff",
      borderRadius: "5px",
      textDecoration: "none",
    },
    image: {
      width: "300px",
      marginBottom: "40px",
    },
  };

  return (
    <div style={styles.container}>
      <img style={styles.image} src={logo} alt="404 Not Found" />
      <h1 style={styles.heading}>Oops! Page not found.</h1>
      <p style={styles.subheading}>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>
      <a href="/" className="primary-button" style={styles.link}>
        Go to Homepage
      </a>
    </div>
  );
}
