import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./sass/index.scss";

import Project from "./pages/project";
import ContactForm from "./pages/contact";
import WelcomePage from "./pages/welcomePage";
import Projects from "./pages/projects";
import PageNotFound from "./pages/404";
import Homepage from "./pages/Slides/Homepage";

function App() {
  const [showWelcome, setShowWelcome] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.welcome-container').classList.add('fade-out');
    }, 2500); // Start fade out after 2.5 seconds

    const removeWelcomePage = setTimeout(() => {
      setShowWelcome(false);
    }, 3500); // Remove welcome page after 3.5 seconds

    return () => {
      clearTimeout(timer);
      clearTimeout(removeWelcomePage);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        {showWelcome ? (
          <WelcomePage />
        ) : (
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/project" element={<Project />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
