import React from "react";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; {currentYear} Denyark. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
}
