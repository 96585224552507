import React from "react";
import image from "../../images/bg3.jpg";
import "../../sass/design.scss";
import SocialIcons from "./SocialIcons";

export default function Deploy() {
  return (
    <div style={styles.container} className="design-container">
      <div className="design-overlay"></div>
      <div className="design-content">
        <SocialIcons />
        <h1 style={styles.text} className="design-text">
          Ready for Launch
        </h1>
        <p style={styles.description}>
          We don’t stop at development; we ensure your project is smoothly
          deployed and fully operational. From setting up environments to
          managing releases, our team handles every detail to make sure your
          application performs flawlessly in the real world. Let us take care of
          the deployment so you can focus on what’s next.
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundImage: `url(${image})`,
  },

  description: {
    color: "#fff",
    fontSize: "1.2rem",
    margin: "10px 0 0",
  },

  iconLink: {
    color: "#fff",
    textDecoration: "none",
  },
  icon: {
    fontSize: "2rem",
  },
};
